export const voices = {
  "cs-CZ-AntoninNeural": "Czech / Czech Republic",
  "cs-CZ-VlastaNeural": "Czech / Czech Republic",
  "da-DK-ChristelNeural": "Danish / Denmark",
  "da-DK-JeppeNeural": "Danish / Denmark",
  "el-GR-AthinaNeural": "Greek / Greece",
  "el-GR-NestorasNeural": "Greek / Greece",
  "de-AT-IngridNeural": "German / Austria",
  "de-AT-JonasNeural": "German / Austria",
  "de-DE-AmalaNeural": "German / Germany",
  "de-DE-BerndNeural": "German / Germany",
  "de-DE-ConradNeural": "German / Germany",
  "de-DE-ElkeNeural": "German / Germany",
  "de-DE-GiselaNeural": "German / Germany",
  "de-DE-ChristophNeural": "German / Germany",
  "de-DE-KasperNeural": "German / Germany",
  "de-DE-KatjaNeural": "German / Germany",
  "de-DE-KillianNeural": "German / Germany",
  "de-DE-KlarissaNeural": "German / Germany",
  "de-DE-KlausNeural": "German / Germany",
  "de-DE-LouisaNeural": "German / Germany",
  "de-DE-MajaNeural": "German / Germany",
  "de-DE-RalfNeural": "German / Germany",
  "de-DE-TanjaNeural": "German / Germany",
  "de-CH-JanNeural": "German / Switzerland",
  "de-CH-LeniNeural": "German / Switzerland",
  "en-GB-AbbiNeural": "English / United Kingdom",
  "en-GB-AlfieNeural": "English / United Kingdom",
  "en-GB-BellaNeural": "English / United Kingdom",
  "en-GB-ElliotNeural": "English / United Kingdom",
  "en-GB-EthanNeural": "English / United Kingdom",
  "en-GB-HollieNeural": "English / United Kingdom",
  "en-GB-LibbyNeural": "English / United Kingdom",
  "en-GB-MaisieNeural": "English / United Kingdom",
  "en-GB-MiaNeural": "English / United Kingdom",
  "en-GB-NoahNeural": "English / United Kingdom",
  "en-GB-OliverNeural": "English / United Kingdom",
  "en-GB-OliviaNeural": "English / United Kingdom",
  "en-GB-RyanNeural": "English / United Kingdom",
  "en-GB-SoniaNeural": "English / United Kingdom",
  "en-GB-ThomasNeural": "English / United Kingdom",
  "en-IE-ConnorNeural": "English / Ireland",
  "en-IE-EmilyNeural": "English / Ireland",
  "en-PH-JamesNeural": "English / Philippines",
  "en-PH-RosaNeural": "English / Philippines",
  "en-US-AmberNeural": "English / United States",
  "en-US-AnaNeural": "English / United States",
  "en-US-AriaNeural": "English / United States",
  "en-US-AshleyNeural": "English / United States",
  "en-US-BrandonNeural": "English / United States",
  "en-US-CoraNeural": "English / United States",
  "en-US-DavisNeural": "English / United States",
  "en-US-ElizabethNeural": "English / United States",
  "en-US-EricNeural": "English / United States",
  "en-US-GuyNeural": "English / United States",
  "en-US-ChristopherNeural": "English / United States",
  "en-US-JacobNeural": "English / United States",
  "en-US-JaneNeural": "English / United States",
  "en-US-JasonNeural": "English / United States",
  "en-US-JennyMultilingualNeural": "English / United States",
  "en-US-JennyNeural": "English / United States",
  "en-US-JessaNeural": "English / United States",
  "en-US-MichelleNeural": "English / United States",
  "en-US-MonicaNeural": "English / United States",
  "en-US-NancyNeural": "English / United States",
  "en-US-RogerNeural": "English / United States",
  "en-US-SaraNeural": "English / United States",
  "en-US-SteffanNeural": "English / United States",
  "en-US-TonyNeural": "English / United States",
  "es-ES-AbrilNeural": "Spanish / Spain, Castilian",
  "es-ES-AlvaroNeural": "Spanish / Spain & Castilian",
  "es-ES-ArnauNeural": "Spanish / Spain & Castilian",
  "es-ES-DarioNeural": "Spanish / Spain & Castilian",
  "es-ES-EliasNeural": "Spanish / Spain & Castilian",
  "es-ES-ElviraNeural": "Spanish / Spain & Castilian",
  "es-ES-EstrellaNeural": "Spanish / Spain & Castilian",
  "es-ES-IreneNeural": "Spanish / Spain & Castilian",
  "es-ES-LaiaNeural": "Spanish / Spain & Castilian",
  "es-ES-LiaNeural": "Spanish / Spain & Castilian",
  "es-ES-NilNeural": "Spanish / Spain & Castilian",
  "es-ES-SaulNeural": "Spanish / Spain & Castilian",
  "es-ES-TeoNeural": "Spanish / Spain & Castilian",
  "es-ES-TrianaNeural": "Spanish / Spain & Castilian",
  "es-ES-VeraNeural": "Spanish / Spain & Castilian",
  "es-MX-BeatrizNeural": "Spanish / Mexico",
  "es-MX-CandelaNeural": "Spanish / Mexico",
  "es-MX-CarlotaNeural": "Spanish / Mexico",
  "es-MX-CecilioNeural": "Spanish / Mexico",
  "es-MX-DaliaNeural": "Spanish / Mexico",
  "es-MX-GerardoNeural": "Spanish / Mexico",
  "es-MX-JorgeNeural": "Spanish / Mexico",
  "es-MX-LarissaNeural": "Spanish / Mexico",
  "es-MX-LibertoNeural": "Spanish / Mexico",
  "es-MX-LucianoNeural": "Spanish / Mexico",
  "es-MX-MarinaNeural": "Spanish / Mexico",
  "es-MX-NuriaNeural": "Spanish / Mexico",
  "es-MX-PelayoNeural": "Spanish / Mexico",
  "es-MX-RenataNeural": "Spanish / Mexico",
  "es-MX-YagoNeural": "Spanish / Mexico",
  "es-AR-ElenaNeural": "Spanish / Argentina",
  "es-AR-TomasNeural": "Spanish / Argentina",
  "es-CL-CatalinaNeural": "Spanish / Chile",
  "es-CL-LorenzoNeural": "Spanish / Chile",
  "es-CO-GonzaloNeural": "Spanish / Colombia",
  "es-CO-SalomeNeural": "Spanish / Colombia",
  "fi-FI-HarriNeural": "Finnish / Finland",
  "fi-FI-NooraNeural": "Finnish / Finland",
  "fi-FI-SelmaNeural": "Finnish / Finland",
  "fil-PH-AngeloNeural": "Filipino / Philippines",
  "fil-PH-BlessicaNeural": "Filipino / Philippines",
  "fr-BE-GerardNeural": "French / Belgium",
  "fr-BE-CharlineNeural": "French / Belgium",
  "fr-FR-AlainNeural": "French / France",
  "fr-FR-BrigitteNeural": "French / France",
  "fr-FR-CelesteNeural": "French / France",
  "fr-FR-ClaudeNeural": "French / France",
  "fr-FR-CoralieNeural": "French / France",
  "fr-FR-DeniseNeural": "French / France",
  "fr-FR-EloiseNeural": "French / France",
  "fr-FR-HenriNeural": "French / France",
  "fr-FR-JacquelineNeural": "French / France",
  "fr-FR-JeromeNeural": "French / France",
  "fr-FR-JosephineNeural": "French / France",
  "fr-FR-MauriceNeural": "French / France",
  "fr-FR-YvesNeural": "French / France",
  "fr-FR-YvetteNeural": "French / France",
  "fr-CH-ArianeNeural": "French / Switzerland",
  "fr-CH-FabriceNeural": "French / Switzerland",
  "hu-HU-NoemiNeural": "Hungarian / Hungary",
  "hu-HU-TamasNeural": "Hungarian / Hungary",
  "it-IT-BenignoNeural": "Italian / Italy",
  "it-IT-CalimeroNeural": "Italian / Italy",
  "it-IT-CataldoNeural": "Italian / Italy",
  "it-IT-DiegoNeural": "Italian / Italy",
  "it-IT-ElsaNeural": "Italian / Italy",
  "it-IT-FabiolaNeural": "Italian / Italy",
  "it-IT-FiammaNeural": "Italian / Italy",
  "it-IT-GianniNeural": "Italian / Italy",
  "it-IT-ImeldaNeural": "Italian / Italy",
  "it-IT-IrmaNeural": "Italian / Italy",
  "it-IT-IsabellaNeural": "Italian / Italy",
  "it-IT-LisandroNeural": "Italian / Italy",
  "it-IT-PalmiraNeural": "Italian / Italy",
  "it-IT-PierinaNeural": "Italian / Italy",
  "it-IT-RinaldoNeural": "Italian / Italy",
  "nb-NO-FinnNeural": "Norwegian / Norway",
  "nb-NO-IselinNeural": "Norwegian / Norway",
  "nb-NO-PernilleNeural": "Norwegian / Norway",
  "nl-BE-ArnaudNeural": "Dutch / Belgium",
  "nl-BE-DenaNeural": "Dutch / Belgium",
  "nl-NL-ColetteNeural": "Dutch / Netherlands",
  "nl-NL-FennaNeural": "Dutch / Netherlands",
  "nl-NL-MaartenNeural": "Dutch / Netherlands",
  "pl-PL-AgnieszkaNeural": "Polish / Poland",
  "pl-PL-MarekNeural": "Polish / Poland",
  "pl-PL-ZofiaNeural": "Polish / Poland",
  "ru-RU-DariyaNeural": "Russian / Russia",
  "ru-RU-DmitryNeural": "Russian / Russia",
  "ru-RU-SvetlanaNeural": "Russian / Russia",
  "sk-SK-LukasNeural": "Slovak / Slovakia",
  "sk-SK-ViktoriaNeural": "Slovak / Slovakia",
  "sv-SE-HilleviNeural": "Swedish / Sweden",
  "sv-SE-MattiasNeural": "Swedish / Sweden",
  "sv-SE-SofieNeural": "Swedish / Sweden",
  "uk-UA-OstapNeural": "Ukrainian / Ukraine",
  "uk-UA-PolinaNeural": "Ukrainian / Ukraine",
  "tr-TR-AhmetNeural": "Turkish / Turkey",
  "tr-TR-EmelNeural": "Turkish / Turkey",
  "pt-PT-DuarteNeural": "Portuguese / Portugal",
  "pt-PT-FernandaNeural": "Portuguese / Portugal",
  "pt-PT-RaquelNeural": "Portuguese / Portugal",
  "hi-IN-MadhurNeural": "Hindi / India",
  "hi-IN-SwaraNeural": "Hindi / India",
  "cy-GB-AledNeural": "Welsh / United Kingdom",
  "cy-GB-NiaNeural": "Welsh / United Kingdom",
  "lv-LV-EveritaNeural": "Latvian / Latvia",
  "lv-LV-NilsNeural": "Latvian / Latvia",
  "zh-CN-YunzeNeural": "Chinese / Mandarin, Simplified",
  "bg-BG-BorislavNeural": "Bulgarian / Bulgaria",
  "bg-BG-KalinaNeural": "Bulgarian / Bulgaria",
  "et-EE-AnuNeural": "Estonian / Estonia",
  "et-EE-KertNeural": "Estonian / Estonia",
  "id-ID-ArdiNeural": "Indonesian / Indonesia",
  "id-ID-GadisNeural": "Indonesian / Indonesia",
  "ja-JP_EmiV3Voice": "Japanese / Japan",
  "ja-JP-KeitaNeural": "Japanese / Japan",
  "ja-JP-NanamiNeural": "Japanese / Japan",
  "ko-KR_HyunjunVoice": "Korean / Korea",
  "ko-KR-InJoonNeural": "Korean / Korea",
  "ko-KR_SiWooVoice": "Korean / Korea",
  "ko-KR-SunHiNeural": "Korean / Korea",
  "ko-KR_YoungmiVoice": "Korean / Korea",
  "ko-KR_YunaVoice": "Korean / Korea",
  "lt-LT-LeonasNeural": "Lithuanian / Lithuania",
  "lt-LT-OnaNeural": "Lithuanian / Lithuania",
  "ro-RO-AlinaNeural": "Romanian / Romania",
  "ro-RO-EmilNeural": "Romanian / Romania",
  "sl-SI-PetraNeural": "Slovenian / Slovenia",
  "sl-SI-RokNeural": "Slovenian / Slovenia",
  "gu-IN-DhwaniNeural": "Gujarati / India",
  "gu-IN-NiranjanNeural": "Gujarati / India",
  "bn-IN-TanishaaNeural": "Bengali / Bengal",
  "bn-IN-BashkarNeural": "Bengali / Bengal",
  "ta-IN-PallaviNeural": "Tamil / India",
  "ta-IN-ValluvarNeural": "Tamil / India",
  "si-LK-ThiliniNeural": "Sinhala / Sri Lanka",
  "si-LK-SameeraNeural": "Sinhala / Sri Lanka",
  "ms-MY-YasminNeural": "Malay / Malaysia",
  "ms-MY-OsmanNeural": "Malay / Malaysia",
  "ar-AE-FatimaNeural": "Arabic / United Arab Emirates",
  "ar-AE-HamdanNeural": "Arabic / United Arab Emirates",
  "he-IL-AvriNeural": "Hebrew / Israel",
  "he-IL-HilaNeural": "Hebrew / Israel",
  "vi-VN-HoaiMyNeural": "Vietnamese / Vietnam",
  "vi-VN-NamMinhNeural": "Vietnamese / Vietnam",
  "az-AZ-BanuNeural": "Azerbaijani / Azerbaijan",
  "az-AZ-BabekNeural": "Azerbaijani / Azerbaijan",
  "fr-CA-SylvieNeural": "French / Canada",
  "fr-CA-JeanNeural": "French / Canada",
  "fr-CA-AntoineNeural": "French / Canada",
  "ur-IN-GulNeural": "Urdu / India",
  "ur-IN-SalmanNeural": "Urdu / India",
  "ur-PK-UzmaNeural": "Urdu / Pakistan",
  "ur-PK-AsadNeural": "Urdu / Pakistan",
  "yue-CN-XiaoMinNeural": "Chinese / Cantonese, Simplified",
  "yue-CN-YunSongNeural": "Chinese / Cantonese, Simplified",
  "zh-CN-XiaoxiaoNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunxiNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunjianNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoyiNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-YunyangNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaochenNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaohanNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaomengNeural": "Chinese / Mandarin, Simplified",
  "zh-CN-XiaoshuangNeural": "Chinese / Mandarin, Simplified",
  "zh-HK-HiuMaanNeural": "Chinese / Cantonese, Traditional",
  "zh-HK-WanLungNeural ": "Chinese / Cantonese, Traditional",
  "zh-HK-HiuGaaiNeural": "Chinese / Cantonese, Traditional",
};
