import { io } from "socket.io-client";

export class SocketService {
  socket = null;
  constructor() {}

  setupSocketConnection() {
    if (!this.socket) {
      this.socket = io("/");
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  sendMessage(message, params) {
    if (this.socket) {
      this.socket.emit(message, params);
    } else {
      throw new Error("Socket isn't setup");
    }
  }

  setHandler(event, handler) {
    if (this.socket) {
      this.socket.on(event, handler);
    } else {
      throw new Error("Socket isn't setup");
    }
  }
}

export default new SocketService();
