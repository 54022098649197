<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-form ref="form" v-model="valid">
            <v-card-text>
              <v-row class="mt-4 mb-2">
                <v-col cols="12" md="6">
                  <h2 class="font-weight-bold mb-8">Party 1</h2>
                  <v-autocomplete
                    v-model="callSettings.target1.prefix"
                    :items="preparedPhonePrefix"
                    item-text="label"
                    item-value="value"
                    label="Prefix"
                    class="max-width"
                  />

                  <v-text-field
                    v-model="callSettings.target1.phoneNumber"
                    :rules="[
                      rules.required,
                      rules.onlyNumbers,
                      rules.phoneNumber.length,
                    ]"
                    maxlength="20"
                    label="Phone number"
                    hide-details="auto"
                    class="mb-6 max-width"
                  ></v-text-field>

                  <v-autocomplete
                    v-model="callSettings.target1.voice"
                    :items="preparedVoices"
                    item-text="label"
                    item-value="value"
                    label="Voices"
                    class="max-width"
                  />
                </v-col>

                <v-divider vertical class="d-sm-none d-md-block" />

                <v-col cols="12" md="6">
                  <h2 class="font-weight-bold mb-8">Party 2</h2>
                  <v-autocomplete
                    v-model="callSettings.target2.prefix"
                    :items="preparedPhonePrefix"
                    item-text="label"
                    item-value="value"
                    label="Prefix"
                    class="max-width"
                  />

                  <v-text-field
                    v-model="callSettings.target2.phoneNumber"
                    :rules="[
                      rules.required,
                      rules.onlyNumbers,
                      rules.phoneNumber.length,
                    ]"
                    maxlength="20"
                    label="Phone number"
                    hide-details="auto"
                    class="mb-6 max-width"
                  ></v-text-field>

                  <v-autocomplete
                    v-model="callSettings.target2.voice"
                    :items="preparedVoices"
                    item-text="label"
                    item-value="value"
                    label="Voices"
                    class="max-width"
                  />
                </v-col>
              </v-row>

              <v-row v-if="showSettings">
                <v-col>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Settings
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-form>
                          <v-slider
                            v-model="callSettings.originalVolume"
                            label="Original speech volume:"
                            hint="Range is 0.0 - 1.0, default is 0.1"
                            persistent-hint
                            thumb-label="always"
                            max="1"
                            min="0"
                            step="0.01"
                            class="mb-8"
                          />

                          <v-slider
                            v-model="callSettings.translatedVolume"
                            label="Translated speech volume:"
                            hint="Range is 0.0 - 1.0, default is 0.15"
                            persistent-hint
                            thumb-label="always"
                            max="1"
                            min="0"
                            step="0.01"
                            class="mb-8"
                          />

                          <v-slider
                            v-model="callSettings.splitAfterCharCount"
                            label="Split after char count:"
                            hint="Specifies the minimum number of characters after which a long sentence is allowed to split (range is 0 - 50, default 20)"
                            persistent-hint
                            thumb-label="always"
                            max="50"
                            min="0"
                            step="1"
                            class="mb-8"
                          />

                          <v-slider
                            v-model="callSettings.equalIntervalSplitRatio"
                            label="Equal interval split ratio:"
                            hint="Sets the ratio of the length of a stable initial part of consequent translations where a split may occur (range is 0.0 - 1.0, default is 0.5)"
                            persistent-hint
                            thumb-label="always"
                            max="1"
                            min="0"
                            step="0.01"
                            class="mb-8"
                          />
                        </v-form>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

              <v-row justify="space-around" class="mt-10 mb-7">
                <v-btn
                  depressed
                  large
                  color="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="makeCall"
                >
                  Make Call
                </v-btn>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" class="mb-4">
        <v-card ref="resultCard">
          <v-card-text>
            <h2 class="font-weight-bold mb-4">Call Transcript</h2>
            <div
              v-html="preparedResult"
              ref="result"
              style="
                height: 250px;
                overflow-y: scroll;
                background-color: #3f3f3f;
                color: #ffffff;
                padding: 8px 22px;
                font-family: 'Courier New', monospace;
                line-height: 1.2;
              "
            ></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { voices } from "@/constants/voices";
import { phonePrefixes } from "@/constants/phonePrefixes";
import SocketService from "@/services/SocketService";

export default {
  name: "MainComponent",

  data: () => ({
    voices: voices,
    phonePrefixes: phonePrefixes,
    loading: false,
    result: "",

    callSettings: {
      target1: {
        prefix: phonePrefixes.Czechia,
        phoneNumber: "",
        voice: voices[0],
      },
      target2: {
        prefix: phonePrefixes.Czechia,
        phoneNumber: "",
        voice: voices[0],
      },
      originalVolume: 0.05,
      translatedVolume: 0.05,
      splitAfterCharCount: 22,
      equalIntervalSplitRatio: 0.55,
    },

    valid: true,
    rules: {
      required: (value) => !!value || "Required.",
      onlyNumbers: (value) =>
        /^\d+$/.test(value) || "Allowed only digits without spaces.",
      phoneNumber: {
        length: (value) => value.length <= 20 || "Max 20 characters",
      },
    },
  }),

  computed: {
    showSettings() {
      return window.location.hostname !== "livetranslate.vocalls.ai";
    },

    preparedPhonePrefix() {
      return Object.keys(phonePrefixes).map(function (key) {
        return {
          label: `${phonePrefixes[key]} ${key}`,
          value: phonePrefixes[key],
        };
      });
    },

    preparedVoices() {
      return Object.keys(voices).map(function (key) {
        return {
          label: `${key} (${voices[key]})`,
          value: key,
        };
      });
    },

    preparedResult() {
      return (
        this.result
          .replaceAll("\n", "<br>")
          // eslint-disable-next-line vue/no-parsing-error
          .replaceAll("[40m[32minfo[39m[22m[49m", "<span style='color: #8ae234'>info</span>")
          // eslint-disable-next-line vue/no-parsing-error
          .replaceAll("[40m[1m[33mwarn[39m[22m[49m", "<span style='color: #e2ab34'>warn</span>")
          // eslint-disable-next-line vue/no-parsing-error
          .replaceAll("[41m[30mfail[39m[22m[49m", "<span style='color: #e23a34'>fail</span>")
      );
    },
  },

  methods: {
    async makeCall() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        this.result = "";
        SocketService.sendMessage("makeCall", this.callSettings);
        this.$refs.resultCard.$el.scrollIntoView({
          block: "end",
          scrollBehavior: "smooth",
        });
      }
    },
  },

  created() {
    SocketService.setupSocketConnection();
    SocketService.setHandler("message", (msg) => {
      this.result = msg;
      Vue.nextTick(() => {
        this.$refs.result.scrollTop = this.$refs.result.scrollHeight;
      });
    });
    SocketService.setHandler("endTask", (msg) => {
      this.loading = false;
      this.result = msg;
      Vue.nextTick(() => {
        this.$refs.result.scrollTop = this.$refs.result.scrollHeight;
      });
    });
  },

  beforeDestroy() {
    SocketService.disconnect();
  },
};
</script>

<style lang="scss">
@media only screen and (min-width: 960px) {
  .max-width {
    max-width: 500px;
  }
}
</style>
